.Home {
  text-align: center;
  font-family: monospace;
}

.Home-header {
  background-color: #112240;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: #61dafb;
}

@media only screen and (max-width: 800px) {
    .Home-header {
        height: 20vh;
        flex-direction: column;
        align-items: center;
    }

    .Home-body {
        height: 70vh;
    }
  }

.Home-body {
    background-color: #162d53;
    color: #61dafb;
    min-height: 80vh;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0%;
}

.Home-footer {
    background-color: #112240;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 10vh;
    max-height: 10vh;
}

.Home-link {
  color: #61dafb;
}

.Links-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

h1 {
    font-size: clamp(1em, 3vw, 2em);
    margin: 0%;
}

.About-p {
    width: 60%;
}

a {
  color: #61dafb;
}